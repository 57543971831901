<!-- eslint-disable prettier/prettier -->
<template>
    <Header title="Photos" />
    <Container>
      <div class="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
        <div v-for="v in photos" :key="v.id" class="">
          <div class="rounded-lg object-cover relative overflow-hidden aspect-w-3 aspect-h-2 group cursor-pointer" v-on:click="
                                  open = true;
                                  photoId = require('@/assets/images/' + v.id + '.jpg')
                                  desc = v.topic
                                ">
            <img :src="require('@/assets/images/' + v.id + '.jpg')" class="object-cover transform" :alt="v.alt"  :photoId= "require('@/assets/images/' + v.id + '.jpg')" desc = v.topic/>
          </div>
        </div>
      </div>
    </Container>
  
    <SimpleBrandCTA />
  
  <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
  
          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="sm:flex sm:items-start mt-8">
                <div class="text-center sm:mt-0 sm:text-left  w-full">
                  <div class="aspect-w-16 aspect-h-9 w-full">
                    <img :src="photoId" class="object-cover transform" />
                  </div>
                  <div class="">
                    {{desc}}
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  
  </template>
<script>
  import { ref } from 'vue'
  import Container from "../../components/layouts/Container.vue";
  import SimpleBrandCTA from "../../components/layouts/cta/SimpleBrandCTA.vue";
  import Header from "@/components/base/Header";
  import { Dialog, DialogOverlay,  TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { XIcon } from '@heroicons/vue/outline'
  
  const photos = [
    {
      topic: "The fundraiser for CMHA Halton is ready to go.",
      id: "standup",
      alt: "stand up"
    },
    {
      topic: "Ted with Burlington Mayor Marianne Meed Ward.",
      id: "mayor",
      alt: "pic"
    },
    {
      topic: "Ted shares his story of his battle with anxiety.",
      id: "address",
      alt: "pic3"
    },
    {
      topic: "CMHA Halton reps with Ted.",
      id: "cmhareps",
      alt: "pic"
    },
    {
      topic: "Ted and the Spoons at the end of the show.",
      id: "endofshow",
      alt: "pic"
    },
    {
      topic: "Ted presents cheque for 25K to CMHA Halton.",
      id: "cheque",
      alt: "pic"
    },
    {
      topic: "Ron Foxcroft with Ted.",
      id: "cheque4",
      alt: "pic"
    },
    {
      topic: "Ted and LiUNA reps at their cheque presentation.",
      id: "cheque3",
      alt: "pic"
    },
  ];
  
  export default {
    components: {
      Header,
      SimpleBrandCTA,
      Container,  
      Dialog,
      DialogOverlay,
      TransitionChild,
      TransitionRoot,
      XIcon,
    },
  
    setup() {
      const open = ref(false)
      const photoId = ref("");
      const desc = ref("");
      return {
        open,
        photoId,
        desc
      }
    },
    data() {
      return {
        photos,
      };
    },
     methods: {
        openVideo(id){
          alert(id);
        }
      },
  };
  </script>
  