<template>
  <Container>
    <div
      class="
        max-w-7xl
        mx-auto
        py-12
        px-4
        sm:px-8
        lg:py-20
        bg-accent-light
        rounded-xl
        flex flex-col
        items-center
        text-center
      "
    >
      <h2 class="mb-0">Connect with Ted</h2>
      <div class="w-24 h-1 my-4 bg-accent"></div>
      <p class="max-w-2xl text-lg text-accent-darkest">
        Corus Radio Hamilton 2021 Hall of Fame Inductee,
Health and Wellness advocate,
Public speaker, charity fundraiser.
      </p>
      <div class="mt-3 flex space-x-3 items-center">
        <a class="transition hover:opacity-75" target="_blank" href="https://twitter.com/mrtedmichaels">
          <img src="@/assets/twitter.svg" alt="" />
        </a>
        <a class="transition hover:opacity-75" title="Visit Facebook page" href="https://www.facebook.com/ted.michaels.1" target="_blank">
              <img src="@/assets/facebook.svg" alt="" />
        </a>
        <a class="transition hover:opacity-75" title="Visit LinkedIn page" href="https://www.linkedin.com/in/ted-michaels-2b2112/"  target="_blank">
          <img src="@/assets/linkedin.svg" alt="" />
        </a>
        <a class="transition hover:opacity-75" target="_blank"  href="https://www.instagram.com/tedmichaels1954/">
          <img src="@/assets/instagram.svg" alt="" />
        </a>
      </div>
      <a class="btn btn-xl mt-12" href="mailto:TedMichaels1954@outlook.com?subject=Website Inquiry">Send an email</a>
    </div>
  </Container>
</template>

<script>
import Container from "../Container.vue";
import Button from "@/components/base/Button.vue";
export default {
  components: { Button, Container },
};
</script>
